/* Below animations are fore modal created using React-Modal */
.ReactModal__Overlay {
  transition: transform 300ms ease-in-out;
  transition-delay: 100ms;
  transform: scale(0);
  /* transform: translateY(-150%); */
}

.ReactModal__Overlay--after-open {
  transform: scale(1);
  /* transform: translateY(0%); */
}

.ReactModal__Overlay--before-close {
  transform: scale(0);
  /* transform: translateY(-150%); */
}

a {
  cursor: pointer;
}

@font-face {
  font-family: "black";
  src: local("HelveticaNeueBlack"),
    url("../assets/fonts/helvetica/HelveticaNeueBlack.otf") format("opentype");
}

@font-face {
  font-family: "black-italic";
  src: local("HelveticaNeueBlackItalic"),
    url("../assets/fonts/helvetica/HelveticaNeueBlackItalic.otf") format("opentype");
}

@font-face {
  font-family: "bold";
  src: local("HelveticaNeueBold"),
    url("../assets/fonts/helvetica/HelveticaNeueBold.otf") format("opentype");
}

@font-face {
  font-family: "bold-italic";
  src: local("HelveticaNeueBoldItalic"),
    url("../assets/fonts/helvetica/HelveticaNeueBoldItalic.otf") format("opentype");
}

@font-face {
  font-family: "heavy";
  src: local("HelveticaNeueHeavy"),
    url("../assets/fonts/helvetica/HelveticaNeueHeavy.otf") format("opentype");
}

@font-face {
  font-family: "heavy-italic";
  src: local("HelveticaNeueHeavyItalic"),
    url("../assets/fonts/helvetica/HelveticaNeueHeavyItalic.otf") format("opentype");
}

@font-face {
  font-family: "italic";
  src: local("HelveticaNeueItalic"),
    url("../assets/fonts/helvetica/HelveticaNeueItalic.ttf") format("truetype");
}

@font-face {
  font-family: "light";
  src: local("HelveticaNeueLight"),
    url("../assets/fonts/helvetica/HelveticaNeueLight.otf") format("opentype");
}

@font-face {
  font-family: "light-italic";
  src: local("HelveticaNeueLightItalic"),
    url("../assets/fonts/helvetica/HelveticaNeueLightItalic.otf") format("opentype");
}

@font-face {
  font-family: "medium";
  src: local("HelveticaNeueMedium"),
    url("../assets/fonts/helvetica/HelveticaNeueMedium.otf") format("opentype");
}

@font-face {
  font-family: "medium-italic";
  src: local("HelveticaNeueMediumItalic"),
    url("../assets/fonts/helvetica/HelveticaNeueMediumItalic.otf") format("opentype");
}

@font-face {
  font-family: "roman";
  src: local("HelveticaNeueRoman"),
    url("../assets/fonts/helvetica/HelveticaNeueRoman.otf") format("opentype");
}

@font-face {
  font-family: "thin";
  src: local("HelveticaNeueThin"),
    url("../assets/fonts/helvetica/HelveticaNeueThin.otf") format("opentype");
}

@font-face {
  font-family: "thin-light";
  src: local("HelveticaNeueThinItalic"),
    url("../assets/fonts/helvetica/HelveticaNeueThinItalic.otf") format("opentype");
}

@font-face {
  font-family: "ultra-light";
  src: local("HelveticaNeueUltraLight"),
    url("../assets/fonts/helvetica/HelveticaNeueUltraLight.otf") format("opentype");
}

@font-face {
  font-family: "light-italic";
  src: local("HelveticaNeueUltraLightItalic"),
    url("../assets/fonts/helvetica/HelveticaNeueUltraLightItalic.otf") format("opentype");
}

h1, h2, h3, h4, h5, h6 {
  font-family: "medium";
  font-weight: 500 !important;
}

h2, h3, h4, h5 {
  line-height: 1.2 !important;
}

p {
  font-family: "light";
  font-weight: 400 !important;
}

html, body {
  background: linear-gradient(to bottom, #EEFCF3, #FBFFFC);
  background-color: #FBFFFC !important;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

input {
  background-color: transparent;
}